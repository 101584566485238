<template>
  <div class="auth-bg min-h-screen h-max lg:flex">
    <auth-layout/>
    <div class="w-full h-max pb-10 lg:ml-1/2 px-4 sm:px-6 lg:px-12 xl:pl-24 xl:pr-20 lg:mt-16 lg:max-w-xxl ">
      <a class="block w-max mx-auto lg:hidden mt-8 cursor-pointer">
        <img src="/assets/images/LoanSpot-logo_white.svg" />
      </a>

      <div class="mt-8 lg:mt-0 bg-white px-5 sm:px-6 lg:px-0 rounded-xl lg:rounded-none pb-10">
        <h3 class="big-title text-6 xl:text-8 text-title pt-8 lg:pt-0">
          You’re almost there
        </h3>
        <p class="text-sm text-sec-text mt-1 xl:mt-2 w-70 xl:w-full">
          Complete your loan application by filling in these information.
        </p>

        <div class="my-10">
          <svg v-if="tierOne" class="w-full" viewBox="0 0 544 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1" fill="white">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M520 48C533.255 48 544 37.2548 544 24C544 10.7452 533.255 0 520 0C510.743 0 502.71 5.24089 498.707 12.9174C496.959 16.2682 493.818 19 490.039 19H50C47.2386 19 45 21.2386 45 24C45 26.7614 47.2386 29 50 29H490.039C493.818 29 496.959 31.7318 498.707 35.0826C502.71 42.7591 510.743 48 520 48Z"/>
            </mask>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M520 48C533.255 48 544 37.2548 544 24C544 10.7452 533.255 0 520 0C510.743 0 502.71 5.24089 498.707 12.9174C496.959 16.2682 493.818 19 490.039 19H50C47.2386 19 45 21.2386 45 24C45 26.7614 47.2386 29 50 29H490.039C493.818 29 496.959 31.7318 498.707 35.0826C502.71 42.7591 510.743 48 520 48Z" fill="white"/>
            <path d="M498.707 35.0826L499.593 34.6202L498.707 35.0826ZM498.707 12.9174L497.82 12.455L498.707 12.9174ZM543 24C543 36.7025 532.703 47 520 47V49C533.807 49 545 37.8071 545 24H543ZM520 1C532.703 1 543 11.2975 543 24H545C545 10.1929 533.807 -1 520 -1V1ZM499.593 13.3798C503.431 6.02066 511.13 1 520 1V-1C510.356 -1 501.989 4.46112 497.82 12.455L499.593 13.3798ZM50 20H490.039V18H50V20ZM490.039 28H50V30H490.039V28ZM520 47C511.13 47 503.431 41.9793 499.593 34.6202L497.82 35.545C501.989 43.5389 510.356 49 520 49V47ZM490.039 30C493.308 30 496.17 32.3813 497.82 35.545L499.593 34.6202C497.748 31.0824 494.329 28 490.039 28V30ZM44 24C44 27.3137 46.6863 30 50 30V28C47.7909 28 46 26.2091 46 24H44ZM50 18C46.6863 18 44 20.6863 44 24H46C46 21.7909 47.7909 20 50 20V18ZM497.82 12.455C496.17 15.6187 493.308 18 490.039 18V20C494.329 20 497.748 16.9176 499.593 13.3798L497.82 12.455Z" fill="#E5E9F2" mask="url(#path-1-inside-1)"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M24 -4.9831e-06C10.7452 -6.14187e-06 3.25692e-06 10.7452 2.09815e-06 24C9.39372e-07 37.2548 10.7452 48 24 48C33.257 48 41.2899 42.7591 45.2935 35.0826C47.041 31.7319 50.1816 29 53.9608 29L76 29C78.7614 29 81 26.7614 81 24C81 21.2386 78.7614 19 76 19L53.9608 19C50.1817 19 47.041 16.2681 45.2935 12.9174C41.2899 5.24088 33.257 -4.17383e-06 24 -4.9831e-06Z" fill="url(#paint0_linear)"/>
            <path d="M23.328 16.672C23.232 17.656 22.92 18.376 22.416 18.808C21.912 19.264 21.096 19.528 19.992 19.6L18.432 19.744V22.384H22.488V34H26.04V16.672H23.328Z" fill="white"/>
            <path d="M513.95 34H526.238V30.952H518.918L521.726 28.696C523.334 27.424 524.462 26.272 525.11 25.24C525.758 24.232 526.094 23.152 526.094 22.024C526.094 20.392 525.518 19.048 524.414 18.04C523.31 17.032 521.846 16.504 520.022 16.504C519.206 16.504 518.414 16.648 517.694 16.912C516.95 17.176 516.302 17.56 515.75 18.04C515.15 18.568 514.694 19.192 514.382 19.888C514.046 20.608 513.902 21.352 513.902 22.144C513.902 22.48 513.926 22.792 513.974 23.08C514.022 23.368 514.094 23.656 514.19 23.968H517.526C517.43 23.728 517.358 23.464 517.31 23.176C517.262 22.912 517.238 22.648 517.238 22.36C517.238 21.544 517.478 20.872 517.982 20.32C518.486 19.792 519.134 19.504 519.95 19.504C520.742 19.504 521.39 19.768 521.87 20.272C522.35 20.776 522.59 21.448 522.59 22.264C522.59 22.768 522.446 23.272 522.206 23.752C521.966 24.232 521.582 24.76 521.054 25.312C520.79 25.552 520.502 25.84 520.166 26.128C519.83 26.44 519.23 26.944 518.414 27.616L513.95 31.288V34Z" fill="#121F3E"/>
            <defs>
              <linearGradient id="paint0_linear" x1="78.6375" y1="48.8" x2="63.5669" y2="-5.46841" gradientUnits="userSpaceOnUse">
                <stop stop-color="#5B7BFD"/>
                <stop offset="1" stop-color="#3752C1"/>
              </linearGradient>
            </defs>
          </svg>
          <svg v-if="tierTwo" class="w-full" viewBox="0 0 544 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 24C1.15877e-06 10.7452 10.7452 -1.15877e-06 24 0C33.257 8.09272e-07 41.2899 5.2409 45.2935 12.9174C47.041 16.2682 50.1817 19 53.9608 19L490.039 19.0001C493.818 19.0001 496.959 16.2682 498.707 12.9174C502.71 5.24094 510.743 4.17881e-05 520 4.17881e-05C533.255 4.17881e-05 544 10.7452 544 24C544 37.2549 533.255 48 520 48C510.743 48 502.71 42.7592 498.707 35.0827C496.959 31.7319 493.818 29 490.039 29L53.9608 29C50.1817 29 47.041 31.7318 45.2935 35.0826C41.2899 42.7591 33.257 48 24 48C10.7452 48 -2.09815e-06 37.2548 0 24Z" fill="url(#paint0_linear)"/>
            <path d="M23.328 16.672C23.232 17.656 22.92 18.376 22.416 18.808C21.912 19.264 21.096 19.528 19.992 19.6L18.432 19.744V22.384H22.488V34H26.04V16.672H23.328Z" fill="white"/>
            <path d="M513.95 34H526.238V30.952H518.918L521.726 28.696C523.334 27.424 524.462 26.272 525.11 25.24C525.758 24.232 526.094 23.152 526.094 22.024C526.094 20.392 525.518 19.048 524.414 18.04C523.31 17.032 521.846 16.504 520.022 16.504C519.206 16.504 518.414 16.648 517.694 16.912C516.95 17.176 516.302 17.56 515.75 18.04C515.15 18.568 514.694 19.192 514.382 19.888C514.046 20.608 513.902 21.352 513.902 22.144C513.902 22.48 513.926 22.792 513.974 23.08C514.022 23.368 514.094 23.656 514.19 23.968H517.526C517.43 23.728 517.358 23.464 517.31 23.176C517.262 22.912 517.238 22.648 517.238 22.36C517.238 21.544 517.478 20.872 517.982 20.32C518.486 19.792 519.134 19.504 519.95 19.504C520.742 19.504 521.39 19.768 521.87 20.272C522.35 20.776 522.59 21.448 522.59 22.264C522.59 22.768 522.446 23.272 522.206 23.752C521.966 24.232 521.582 24.76 521.054 25.312C520.79 25.552 520.502 25.84 520.166 26.128C519.83 26.44 519.23 26.944 518.414 27.616L513.95 31.288V34Z" fill="white"/>
            <defs>
            <linearGradient id="paint0_linear" x1="15.8667" y1="-0.800001" x2="18.2796" y2="57.5539" gradientUnits="userSpaceOnUse">
            <stop stop-color="#5B7BFD"/>
            <stop offset="1" stop-color="#3752C1"/>
            </linearGradient>
            </defs>
          </svg>
          <div class="mt-3 flex items-center justify-between">
            <span :class="tierOne ? 'text-header' : 'text-accent-3'" class="text-xs md:text-sm">Personal information</span>
            <span :class="tierTwo ? 'text-header' : 'text-accent-3'" class="text-xs md:text-sm">Financial details</span>
          </div>
        </div>

        <form class="mt-10">
          <div v-if="tierOne" class="">
            <div class="flex flex-col  mt-4 md:mt-0">
              <label class="text-xs inline-block text-title title">Date of Birth</label>
              <div class="flex items-center w-full rounded-lg mt-1">
                <svg class="absolute ml-4" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 1.33333H12.6667C12.8435 1.33333 13.013 1.40357 13.1381 1.5286C13.2631 1.65362 13.3333 1.82319 13.3333 2V12.6667C13.3333 12.8435 13.2631 13.013 13.1381 13.1381C13.013 13.2631 12.8435 13.3333 12.6667 13.3333H0.666667C0.489856 13.3333 0.320286 13.2631 0.195262 13.1381C0.0702379 13.013 0 12.8435 0 12.6667V2C0 1.82319 0.0702379 1.65362 0.195262 1.5286C0.320286 1.40357 0.489856 1.33333 0.666667 1.33333H3.33333V0H4.66667V1.33333H8.66667V0H10V1.33333ZM12 6.66667H1.33333V12H12V6.66667ZM8.66667 2.66667H4.66667V4H3.33333V2.66667H1.33333V5.33333H12V2.66667H10V4H8.66667V2.66667ZM2.66667 8H4V9.33333H2.66667V8ZM6 8H7.33333V9.33333H6V8ZM9.33333 8H10.6667V9.33333H9.33333V8Z" fill="#6D7D93"/>
                </svg>
                <date-picker
                  class="w-full"
                  v-model="formBody.dateOfBirth"
                  mode="date"
                  :max-date="minDate"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="bg-transparent w-full h-12 text-title border outline-none rounded-lg text-sm pr-4 pl-10"
                      :value="inputValue"
                      placeholder="Date of birth"
                      v-on="inputEvents"
                    />
                  </template>
                </date-picker>
              </div>
            </div>

            <div class="flex flex-col  mt-5">
              <label class="text-xs inline-block text-title title">Marital Status</label>
              <div class="flex items-center w-full rounded-lg mt-1">
                <select
                  v-model="formBody.maritalStatus"
                  class="bg-transparent w-full h-12 text-title border outline-none rounded-lg text-sm pl-4 minimal cursor-pointer">
                  <option>Select</option>
                  <option value="Single">Single</option>
                  <option value="Married">Married</option>
                  <option value="Divorced">Divorced</option>
                  <option value="Separated">Separated</option>
                  <option value="Widowed">Widowed</option>
                </select>
              </div>
            </div>

            <div class="flex flex-col mt-5">
              <label class="text-xs inline-block text-title title">Home Address</label>
              <div class="flex items-center w-full h-12 rounded-lg mt-1">
                <svg class="absolute ml-4" width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 14.4853L1.75734 10.2426C0.918229 9.4035 0.346791 8.3344 0.115286 7.17051C-0.11622 6.00663 0.00260456 4.80023 0.456733 3.70388C0.91086 2.60753 1.6799 1.67046 2.66659 1.01118C3.65328 0.351892 4.81332 0 6 0C7.18669 0 8.34672 0.351892 9.33342 1.01118C10.3201 1.67046 11.0891 2.60753 11.5433 3.70388C11.9974 4.80023 12.1162 6.00663 11.8847 7.17051C11.6532 8.3344 11.0818 9.4035 10.2427 10.2426L6 14.4853ZM9.3 9.29995C9.9526 8.64728 10.397 7.81576 10.577 6.91053C10.7571 6.0053 10.6646 5.06701 10.3114 4.21432C9.95817 3.36162 9.36003 2.63282 8.59261 2.12006C7.82519 1.6073 6.92296 1.33362 6 1.33362C5.07704 1.33362 4.17481 1.6073 3.40739 2.12006C2.63997 2.63282 2.04183 3.36162 1.68861 4.21432C1.33539 5.06701 1.24294 6.0053 1.42297 6.91053C1.603 7.81576 2.04741 8.64728 2.7 9.29995L6 12.5999L9.3 9.29995ZM6 7.33328C5.64638 7.33328 5.30724 7.19281 5.05719 6.94276C4.80715 6.69271 4.66667 6.35357 4.66667 5.99995C4.66667 5.64633 4.80715 5.30719 5.05719 5.05714C5.30724 4.80709 5.64638 4.66661 6 4.66661C6.35362 4.66661 6.69276 4.80709 6.94281 5.05714C7.19286 5.30719 7.33334 5.64633 7.33334 5.99995C7.33334 6.35357 7.19286 6.69271 6.94281 6.94276C6.69276 7.19281 6.35362 7.33328 6 7.33328Z" fill="#6D7D93"/>
                </svg>
                <input
                  class="bg-transparent w-full h-12 text-title border outline-none rounded-lg text-sm pr-4 pl-10"
                  type="text"
                  name="Address"
                  autocomplete="off"
                  required
                  placeholder="Home address"
                  id="autocomplete"
                  v-model="formBody.homeAddress"
                />
                <div id="inner"></div>
                <!-- <place-autocomplete-field api-key="AIzaSyDqXl_w9Bv0Y9y-7fk12uwf_6f-Rjl32qI"></place-autocomplete-field> -->
              </div>
              <!-- <vue-google-autocomplete
                id="home-address"
              ></vue-google-autocomplete> -->
            </div>

            <div class="flex flex-col mt-5">
              <label class="text-xs inline-block text-title title">State</label>
              <div class="flex items-center w-full rounded-lg">
                <select
                  v-model="formBody.homeStateId"
                  class="bg-transparent w-full h-12 capitalize text-title border outline-none rounded-lg text-sm pl-4 minimal mt-1 cursor-pointer"
                >
                  <option>Select state</option>
                  <option v-for="(state, i) in statesSorted" :key="i" :value="state.id">{{state.name}}</option>
                </select>
              </div>
            </div>

            <div class="flex flex-col mt-5">
              <label class="text-xs inline-block text-title title">Gender</label>
              <div class="flex items-center w-full rounded-lg">
                <select
                  v-model="formBody.gender"
                  class="bg-transparent w-full h-12 text-title border outline-none rounded-lg text-sm pl-4 minimal mt-1 cursor-pointer"
                >
                  <option selected="selected">Select</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
            </div>

            <div @click.prevent="toNext" class="flex items-center justify-center cursor-pointer bg-primary mt-10 w-full rounded-lg h-14 hover:bg-bg-hover text-white font-medium text-sm tracking-wider title">Next</div>
          </div>

          <div v-if="tierTwo" class="pb-10">
            <div class="flex flex-col mt-10">
              <label class="text-xs inline-block text-title title">Employment Status</label>
              <div class="flex items-center w-full rounded-lg mt-1">
                <select
                  v-model="formBody.employmentStatus"
                  required
                  class="bg-transparent w-full h-12 text-title border outline-none rounded-lg text-sm pl-4 minimal cursor-pointer">
                  <option>Select employment status</option>
                  <option value="employed">Employed</option>
                  <option value="unemployed">Unemployed</option>
                  <option value="self-employed">Self Employed</option>
                </select>
              </div>
            </div>

            <div class="flex flex-col  mt-5">
              <label class="flex flex-col text-xs inline-block text-title title">Work Address
                <span class="flex items-center text-accent-3">
                  <svg class="mr-1.5" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99967 0.833496C10.4057 0.833496 13.1663 3.59483 13.1663 7.00016C13.1663 10.4055 10.4057 13.1668 6.99967 13.1668C3.59434 13.1668 0.833008 10.4055 0.833008 7.00016C0.833008 3.59483 3.59434 0.833496 6.99967 0.833496Z" stroke="#96A0B5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.99642 4.46954V7.41554" stroke="#96A0B5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.99602 9.53078H7.00268" stroke="#96A0B5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  If you own a business, type in your business address</span>
              </label>
              <div class="flex items-center w-full h-12 rounded-lg mt-1">
                <svg class="absolute ml-4" width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 14.4853L1.75734 10.2426C0.918229 9.4035 0.346791 8.3344 0.115286 7.17051C-0.11622 6.00663 0.00260456 4.80023 0.456733 3.70388C0.91086 2.60753 1.6799 1.67046 2.66659 1.01118C3.65328 0.351892 4.81332 0 6 0C7.18669 0 8.34672 0.351892 9.33342 1.01118C10.3201 1.67046 11.0891 2.60753 11.5433 3.70388C11.9974 4.80023 12.1162 6.00663 11.8847 7.17051C11.6532 8.3344 11.0818 9.4035 10.2427 10.2426L6 14.4853ZM9.3 9.29995C9.9526 8.64728 10.397 7.81576 10.577 6.91053C10.7571 6.0053 10.6646 5.06701 10.3114 4.21432C9.95817 3.36162 9.36003 2.63282 8.59261 2.12006C7.82519 1.6073 6.92296 1.33362 6 1.33362C5.07704 1.33362 4.17481 1.6073 3.40739 2.12006C2.63997 2.63282 2.04183 3.36162 1.68861 4.21432C1.33539 5.06701 1.24294 6.0053 1.42297 6.91053C1.603 7.81576 2.04741 8.64728 2.7 9.29995L6 12.5999L9.3 9.29995ZM6 7.33328C5.64638 7.33328 5.30724 7.19281 5.05719 6.94276C4.80715 6.69271 4.66667 6.35357 4.66667 5.99995C4.66667 5.64633 4.80715 5.30719 5.05719 5.05714C5.30724 4.80709 5.64638 4.66661 6 4.66661C6.35362 4.66661 6.69276 4.80709 6.94281 5.05714C7.19286 5.30719 7.33334 5.64633 7.33334 5.99995C7.33334 6.35357 7.19286 6.69271 6.94281 6.94276C6.69276 7.19281 6.35362 7.33328 6 7.33328Z" fill="#6D7D93"/>
                </svg>
                <input
                  class="bg-transparent w-full h-12 text-title border outline-none rounded-lg text-sm pr-4 pl-10"
                  type="text"
                  name="WorkAddress"
                  autocomplete="off"
                  required
                  placeholder="Work address"
                  id="autocomplete-work"
                  v-model="formBody.workAddress"
                />
                <div id="inner"></div>
              </div>
                <!-- <place-autocomplete-field api-key="AIzaSyDqXl_w9Bv0Y9y-7fk12uwf_6f-Rjl32qI"></place-autocomplete-field> -->
            </div>

            <div class="flex flex-col  mt-5">
              <label class="text-xs inline-block text-title title">State</label>
              <div class="flex items-center w-full rounded-lg">
                <select
                  v-model="formBody.workStateId"
                  required
                  class="bg-transparent w-full h-12 text-title border capitalize outline-none rounded-lg text-sm pl-4 minimal  mt-1 cursor-pointer"
                >
                  <option>Select state</option>
                  <option v-for="(state, i) in statesSorted" :key="i"
                  :value="state.id">{{ state.name }}</option>
                </select>
              </div>
            </div>

            <div class="flex flex-col mt-5">
              <label class="flex flex-col text-xs inline-block text-title title">BVN
                <span class="flex items-center text-accent-3">
                  <svg class="mr-1.5" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99967 0.833496C10.4057 0.833496 13.1663 3.59483 13.1663 7.00016C13.1663 10.4055 10.4057 13.1668 6.99967 13.1668C3.59434 13.1668 0.833008 10.4055 0.833008 7.00016C0.833008 3.59483 3.59434 0.833496 6.99967 0.833496Z" stroke="#96A0B5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.99642 4.46954V7.41554" stroke="#96A0B5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.99602 9.53078H7.00268" stroke="#96A0B5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  This is to verify your identity and your account details</span>
              </label>
              <div class="flex items-center w-full h-12 rounded-lg mt-1">
                <svg
                  class="absolute ml-4 h-5 w-5 fill-current"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.3333 14.6663H2.66667C2.48986 14.6663 2.32029 14.5961 2.19526 14.4711C2.07024 14.3461 2 14.1765 2 13.9997V1.99967C2 1.82286 2.07024 1.65329 2.19526 1.52827C2.32029 1.40325 2.48986 1.33301 2.66667 1.33301H13.3333C13.5101 1.33301 13.6797 1.40325 13.8047 1.52827C13.9298 1.65329 14 1.82286 14 1.99967V13.9997C14 14.1765 13.9298 14.3461 13.8047 14.4711C13.6797 14.5961 13.5101 14.6663 13.3333 14.6663ZM12.6667 13.333V2.66634H3.33333V13.333H12.6667ZM5.33333 4.66634H10.6667V5.99967H5.33333V4.66634ZM5.33333 7.33301H10.6667V8.66634H5.33333V7.33301ZM5.33333 9.99967H10.6667V11.333H5.33333V9.99967Z"
                    fill="#B5B5B5"
                  />
                </svg>
                <input
                  class="bg-transparent w-full h-12 text-title border outline-none rounded-lg text-sm pr-4 pl-11"
                  type="text"
                  name="BVN"
                  autocomplete="off"
                  required
                  v-model="formBody.bvn"
                  placeholder="11223345678"
                  maxlength="11"
                />
              </div>
            </div>

            <div class="flex flex-col  mt-5">
              <label class="text-xs inline-block text-title title">Bank name</label>
              <div class="flex items-center w-full rounded-lg mt-1">
                <select
                  class="bg-transparent w-full h-12 text-title border outline-none rounded-lg text-sm pl-4 minimal cursor-pointer"
                  required
                  v-model="formBody.bankId"
                >
                  <option>Select bank</option>
                  <option v-for="(bank, i) in banks" :key="i" :value="bank.id">{{bank.name}}</option>
                </select>
              </div>
            </div>

            <div class="flex flex-col  mt-5">
              <label class="flex flex-col text-xs inline-block text-title title">Bank account number
                <span class="flex items-center text-accent-3">
                  <svg class="mr-1.5" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99967 0.833496C10.4057 0.833496 13.1663 3.59483 13.1663 7.00016C13.1663 10.4055 10.4057 13.1668 6.99967 13.1668C3.59434 13.1668 0.833008 10.4055 0.833008 7.00016C0.833008 3.59483 3.59434 0.833496 6.99967 0.833496Z" stroke="#96A0B5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.99642 4.46954V7.41554" stroke="#96A0B5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.99602 9.53078H7.00268" stroke="#96A0B5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  This is the bank account you would want the loan to be deposited into</span>
              </label>
              <div class="flex flex-col w-full h-12 rounded-lg mt-1">
                <div class="flex items-center w-full">
                  <svg
                    class="absolute ml-4 h-5 w-5 fill-current"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.3333 14.6663H2.66667C2.48986 14.6663 2.32029 14.5961 2.19526 14.4711C2.07024 14.3461 2 14.1765 2 13.9997V1.99967C2 1.82286 2.07024 1.65329 2.19526 1.52827C2.32029 1.40325 2.48986 1.33301 2.66667 1.33301H13.3333C13.5101 1.33301 13.6797 1.40325 13.8047 1.52827C13.9298 1.65329 14 1.82286 14 1.99967V13.9997C14 14.1765 13.9298 14.3461 13.8047 14.4711C13.6797 14.5961 13.5101 14.6663 13.3333 14.6663ZM12.6667 13.333V2.66634H3.33333V13.333H12.6667ZM5.33333 4.66634H10.6667V5.99967H5.33333V4.66634ZM5.33333 7.33301H10.6667V8.66634H5.33333V7.33301ZM5.33333 9.99967H10.6667V11.333H5.33333V9.99967Z"
                      fill="#B5B5B5"
                    />
                  </svg>
                  <input
                    class="bg-transparent w-full h-12 text-title border outline-none rounded-lg text-sm pr-4 pl-11"
                    type="text"
                    name="BankAccount"
                    autocomplete="off"
                    required
                    v-model="formBody.bankAccountNumber"
                    placeholder="1122334567"
                    maxlength="10"
                  />
                    <!-- @keyup="getUserName" -->
                </div>
                <div class="mt-2 mb-10 text-sm text-title uppercase">
                  <span>{{userBankName}}</span>
                  <span
                    class="animate-spin"
                    :class="spinnerName ? '' : 'hidden'"
                  >
                    <svg class="animate-spin" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.2" d="M13.75 7C13.75 10.7279 10.7279 13.75 7 13.75C3.27208 13.75 0.25 10.7279 0.25 7C0.25 3.27208 3.27208 0.25 7 0.25C10.7279 0.25 13.75 3.27208 13.75 7ZM1.6 7C1.6 9.98234 4.01766 12.4 7 12.4C9.98234 12.4 12.4 9.98234 12.4 7C12.4 4.01766 9.98234 1.6 7 1.6C4.01766 1.6 1.6 4.01766 1.6 7Z" fill="#EFEFEF"/>
                      <path d="M7 13.075C7 13.4478 6.69704 13.7535 6.32611 13.7163C5.37081 13.6204 4.44421 13.3216 3.60954 12.8367C2.57975 12.2385 1.72655 11.3785 1.13655 10.344C0.546549 9.3095 0.240758 8.1373 0.250213 6.94641C0.259668 5.75552 0.584032 4.58833 1.19038 3.56331C1.79673 2.5383 2.66348 1.69195 3.70264 1.11018C4.74181 0.528418 5.91639 0.231942 7.10717 0.250851C8.29795 0.26976 9.46253 0.60338 10.4827 1.21785C11.3096 1.71589 12.0177 2.38405 12.5617 3.17514C12.773 3.48231 12.6509 3.89503 12.3242 4.07455C11.9975 4.25407 11.5904 4.13163 11.3715 3.82984C10.9474 3.24504 10.4089 2.74935 9.78616 2.37428C8.97003 1.8827 8.03836 1.61581 7.08574 1.60068C6.13311 1.58555 5.19345 1.82273 4.36211 2.28815C3.53078 2.75356 2.83738 3.43064 2.3523 4.25065C1.86723 5.07066 1.60773 6.00442 1.60017 6.95713C1.59261 7.90984 1.83724 8.8476 2.30924 9.67521C2.78124 10.5028 3.4638 11.1908 4.28763 11.6694C4.91623 12.0345 5.61 12.2678 6.32674 12.3579C6.69663 12.4043 7 12.7022 7 13.075Z" fill="#1656C1"/>
                    </svg>
                  </span>
                </div>
              </div>
            </div>

            <div @click.prevent="updateUser" :disabled="isDisabled" :class="isDisabled ? 'bg-gray-500' : 'bg-primary'" class="focus:outline-none flex items-center justify-center cursor-pointer mt-10 w-full flex items-center justify-center rounded-lg h-14 hover:bg-bg-hover text-white font-medium text-sm tracking-wider">
              <span
                class=""
                :class="spinner ? '' : 'hidden'"
              >
                <svg class="animate-spin" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.2" d="M13.75 7C13.75 10.7279 10.7279 13.75 7 13.75C3.27208 13.75 0.25 10.7279 0.25 7C0.25 3.27208 3.27208 0.25 7 0.25C10.7279 0.25 13.75 3.27208 13.75 7ZM1.6 7C1.6 9.98234 4.01766 12.4 7 12.4C9.98234 12.4 12.4 9.98234 12.4 7C12.4 4.01766 9.98234 1.6 7 1.6C4.01766 1.6 1.6 4.01766 1.6 7Z" fill="#EFEFEF"/>
                  <path d="M7 13.075C7 13.4478 6.69704 13.7535 6.32611 13.7163C5.37081 13.6204 4.44421 13.3216 3.60954 12.8367C2.57975 12.2385 1.72655 11.3785 1.13655 10.344C0.546549 9.3095 0.240758 8.1373 0.250213 6.94641C0.259668 5.75552 0.584032 4.58833 1.19038 3.56331C1.79673 2.5383 2.66348 1.69195 3.70264 1.11018C4.74181 0.528418 5.91639 0.231942 7.10717 0.250851C8.29795 0.26976 9.46253 0.60338 10.4827 1.21785C11.3096 1.71589 12.0177 2.38405 12.5617 3.17514C12.773 3.48231 12.6509 3.89503 12.3242 4.07455C11.9975 4.25407 11.5904 4.13163 11.3715 3.82984C10.9474 3.24504 10.4089 2.74935 9.78616 2.37428C8.97003 1.8827 8.03836 1.61581 7.08574 1.60068C6.13311 1.58555 5.19345 1.82273 4.36211 2.28815C3.53078 2.75356 2.83738 3.43064 2.3523 4.25065C1.86723 5.07066 1.60773 6.00442 1.60017 6.95713C1.59261 7.90984 1.83724 8.8476 2.30924 9.67521C2.78124 10.5028 3.4638 11.1908 4.28763 11.6694C4.91623 12.0345 5.61 12.2678 6.32674 12.3579C6.69663 12.4043 7 12.7022 7 13.075Z" fill="white"/>
                </svg>
              </span>
              <span :class="spinner ? 'hidden' : 'title'">
                {{ signUpBtnText }}
              </span>
            </div>
            <div @click.prevent="toFirst" class="flex items-center justify-center cursor-pointer bg-white mt-10 w-full rounded-lg h-14 hover:bg-bg-white text-primary border border-border font-medium text-sm tracking-wider title">Back</div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import AuthLayout from '../../components/auth/auth-layout.vue';
import { ApiInstance } from '../../utils';
import axios from 'axios';
import { BASE_API } from "../../constants";

export default {
  name: 'Setup',
  components: {
    "date-picker": DatePicker,
    AuthLayout,
  },
  beforeCreate() {
    if (!localStorage.getItem('auth-token')) return this.$router.replace('/login');
    this.$store.dispatch('userProfile');
  },
  async mounted() {
    this.minDate = new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate());
    this.getBanks();
    this.checkComplete();
    await this.getStates();
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile,
    }),
    statesSorted() {
      return this.states;
    },
  },
  data() {
    return {
      // isRequest: false,
      isDisabled: false,
      resolvedName: false,
      spinner: false,
      spinnerName: false,
      tierOne: true,
      tierTwo: false,
      minDate: "",
      states: [],
      userBankName: '',
      banks: [],
      formBody: {
        dateOfBirth: '',
        maritalStatus: 'Select',
        homeAddress: "",
        homeAddressLng: 0,
        homeAddressLat: 0,
        workAddress: "",
        workAddressLng: 0,
        workAddressLat: 0,
        homeStateId: 'Select state',
        workStateId: 'Select state',
        gender: 'Select',
        employmentStatus: 'Select employment status',
        bvn: '',
        bankId: 'Select bank',
        bankAccountNumber: ''
      },
      signUpBtnText: 'Continue'
    }
  },
  methods: {
    filterByAlpha (a, b){
      if(a.firstname < b.firstname) { return -1; }
      if(a.firstname > b.firstname) { return 1; }
      return 0;
    },
    checkComplete() {
    },
    async getBanks() {
      const res = await ApiInstance.get('/bank');
      this.banks = res.data?.data;
    },
    initAutoComplete() {
      // eslint-disable-next-line no-undef
      let autocompleteHome = new google.maps.places.Autocomplete(
        document.getElementById('autocomplete'), {
          componentRestrictions: { 'country': ['NG'] },
        }
      );
      autocompleteHome.addListener('place_changed', () => {
        var place = autocompleteHome.getPlace();
        this.formBody.homeAddress = place.formatted_address;
        this.formBody.homeAddressLat = autocompleteHome.getPlace().geometry.location.lat();
        this.formBody.homeAddressLng = autocompleteHome.getPlace().geometry.location.lng();
      })
    },
    initAutoCompleteWork() {
      // eslint-disable-next-line no-undef
      let autocompleteWork = new google.maps.places.Autocomplete(
        document.getElementById('autocomplete-work'), {
          componentRestrictions: { 'country': ['NG'] },
        }
      );
      autocompleteWork.addListener('place_changed', () => {
        var place = autocompleteWork.getPlace();
        this.formBody.workAddress = place.formatted_address;
        this.formBody.workAddressLat = autocompleteWork.getPlace().geometry.location.lat();
        this.formBody.workAddressLng = autocompleteWork.getPlace().geometry.location.lng();
      })
    },
    toNext() {
      this.tierOne = false;
      this.tierTwo = true;
    },
    toFirst() {
      this.tierOne = true;
      this.tierTwo = false;
    },
    async getStates() {
      const states = await ApiInstance.get('/states');
      this.states = Object.values(states.data?.data).sort(this.filterByAlpha);
    },
    updateUser() {
      if(this.formBody.dateOfBirth === '') {
        return this.$notie.alert({
          type: "error",
          text: "Please fill in your Date of Birth.",
          time: 3,
        });
      }
      if(this.formBody.maritalStatus === 'Select' || '') {
        return this.$notie.alert({
          type: "error",
          text: "Please fill in your Marital Status.",
          time: 3,
        });
      }
      if(this.formBody.homeAddress === '') {
        return this.$notie.alert({
          type: "error",
          text: "Please select your home address.",
          time: 3,
        });
      }
      if(this.formBody.homeStateId === 'Select state' || '') {
        return this.$notie.alert({
          type: "error",
          text: "Please select your state of residence.",
          time: 3,
        });
      }
      if(this.formBody.gender === 'Select' || '') {
        return this.$notie.alert({
          type: "error",
          text: "Please select your gender.",
          time: 3,
        });
      }
      if(this.formBody.employmentStatus === 'Select employment status' || '') {
        return this.$notie.alert({
          type: "error",
          text: "Please select your employment status.",
          time: 3,
        });
      }
      if(this.formBody.workAddress === '') {
        return this.$notie.alert({
          type: "error",
          text: "Please select your work address.",
          time: 3,
        });
      }
      if(this.formBody.workStateId === 'Select state' || '') {
        return this.$notie.alert({
          type: "error",
          text: "Please select your state of employment.",
          time: 3,
        });
      }
      if(this.formBody.bvn === '') {
        return this.$notie.alert({
          type: "error",
          text: "Please fill in your BVN.",
          time: 3,
        });
      }
      if(this.formBody.bankId === 'Select bank' || '') {
        return this.$notie.alert({
          type: "error",
          text: "Please select your bank.",
          time: 3,
        });
      }
      if(this.formBody.bankAccountNumber === '') {
        return this.$notie.alert({
          type: "error",
          text: "Please fill in your Bank account.",
          time: 3,
        });
      }
      this.spinner = true;
      axios.post(`${BASE_API}/user/update`, this.formBody, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth-token')}`
        }
      })
      .then(res => {
        this.spinner = false;
        if(res.data) {
          this.$notie.alert({
            type: "success",
            text: "Account update successful.",
            time: 2,
          });
        }
        return this.$router.push('/');
      })
      .catch(e => {
        this.spinner = false;
        return e
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .auth-bg {
    background: linear-gradient(154.89deg, #5B7BFD -0.2%, #3752C1 82.72%);
    @media (min-width: 1024px) {
      background: #fff;
    }
  }
  .big-title {
    font-family: SohneHalbfett, sans-serif;
  }
  .title {
    font-family: SohneKraftig, sans-serif;
  }
  ::placeholder {
    font-family: SohneLeicht;
    font-size: 0.875rem;
  }
</style>